<script setup lang="ts">
import { computed, ref, toRef, watch } from 'vue'
import InvoiceDetails, { type Charge as InvoiceDetailsCharge } from '@/views/billing/invoicing/InvoiceDetails.vue'
import { faFloppyDisk, faXmark } from '@fortawesome/pro-regular-svg-icons'
import {
  useGetInvoiceConfig, useRemoveBillingCharge,
  useUpdateAssetInvoiceConfig, useUpdateBillingCharge
} from '@/composables/billing/invoices'
import Button from '@/components/button-secondary.vue'
import ButtonPrimary from '@/components/button-primary.vue'
import { useRouter } from 'vue-router'
import { useGetProjectByAssetCode, useUpdatePoNumber } from '@/composables/assets/projects'
import { useSaveMeterConfiguration } from '@/composables/assets/assets'
import ContentLoader from '@/components/content-loader.vue'
import useLoader from '@/composables/loader'
import useNotifications from '@/composables/notifications'
import HeroSplashError from '@/components/hero-splash-error.vue'
import routeNames from '@/router/names'
import type { InvoiceMeterReadings } from '@/features/billing/schemas'
import { ChargeType } from '@/features/billing/constants'
import { roundToMainCurrencyPrecision } from '@/helpers'
import AddRecurringChargeModal from '@/views/billing/invoicing/add-recurring-charge-modal.vue'
import { useModalState } from '@/composables/modal'
import ModalVariantConfirmation from '@/components/modal/modal-variant-confirmation.vue'
import { formatToMainCurrency } from '../../../helpers/formatting'

const router = useRouter()
const props = defineProps<{ assetCode: string }>()
const assetCode = toRef(props, 'assetCode')

const { data: invoiceConfigData, isFetching: invoiceConfigLoading, isError: invoiceConfigDataError } = useGetInvoiceConfig(assetCode)
const { data: projectData, isLoading: projectLoading} = useGetProjectByAssetCode(assetCode)
const { mutateAsync: updateInvoiceConfig } = useUpdateAssetInvoiceConfig(assetCode)
const { mutateAsync: updateProject } = useUpdatePoNumber(assetCode)
const {mutateAsync: updateMeterConfig } = useSaveMeterConfiguration(assetCode)
const { mutateAsync: updateBillingCharge } = useUpdateBillingCharge(assetCode)
const { mutateAsync: removeBillingCharge } = useRemoveBillingCharge(assetCode)
const { isOpen: addChargeModalIsOpen, close: closeAddChargeModal, open: openAddChargeModal } = useModalState()
const confirmChargeDeletion = ref<InvoiceDetailsCharge>()

const { wrap } = useLoader()
const { addSuccess, addError } = useNotifications()

const projectCode = ref<number>(projectData.value?.projectCode ?? 0)
const poNumber = ref<string>(invoiceConfigData.value?.poNumber ?? '')

const owner = computed(() => {
    return {
        id: invoiceConfigData.value?.contractFundEntity.id ?? 0,
        name: invoiceConfigData.value?.contractFundEntity.name ?? "",
        address: invoiceConfigData.value?.contractFundEntity.address ?? "",
        vatNumber: invoiceConfigData.value?.contractFundEntity.vatNumber ?? "",
        bankDetails: {
            accountName: invoiceConfigData.value?.contractFundEntity.accountName ?? "",
            bank: invoiceConfigData.value?.contractFundEntity.bank ?? "",
            accountNumber: invoiceConfigData.value?.contractFundEntity.accountNumber ?? "",
            branchCode: invoiceConfigData.value?.contractFundEntity.branchCode ?? ""
        }
    }
})
const invoice = computed(() => {
    return {
        id: invoiceConfigData.value?.id ?? 0,        
        billingConfigurationId: invoiceConfigData.value?.billingConfigurationId ?? 0,        
        assetCode: invoiceConfigData.value?.assetCode ?? "0", 
        invoiceStatusId: invoiceConfigData.value?.invoiceStatusId ?? 0,       
        invoiceNumber: invoiceConfigData.value?.invoiceNumber ?? "",
        invoiceExternalReferenceNumber: invoiceConfigData.value?.invoiceExternalReferenceNumber ?? "",
        accountReferenceNumber: invoiceConfigData.value?.accountReferenceNumber ?? "",
        poNumber : invoiceConfigData.value?.poNumber ?? "",
        invoiceDate: invoiceConfigData.value?.invoiceDate ?? new Date(),
        periodStartDate:invoiceConfigData.value?.periodStartDate ?? new Date(),
        periodEndDate: invoiceConfigData.value?.periodEndDate ?? new Date(),
        gridTariff: invoiceConfigData.value?.gridTariff ?? 0,
        totalEnergyConsumption: invoiceConfigData.value?.totalEnergyConsumption ?? 0,
        totalGridConsumption: invoiceConfigData.value?.totalGridConsumption ?? 0,
        totalEnergyExported: invoiceConfigData.value?.totalEnergyExported ?? 0,
        totalAmountExcludingTax: invoiceConfigData.value?.totalAmountExcludingTax ?? 0,
        tax: invoiceConfigData.value?.tax ?? 0,
        totalAmountIncludingTax: invoiceConfigData.value?.totalAmountIncludingTax ?? 0,
        totalSavingsToDate: invoiceConfigData.value?.totalSavingsToDate ?? 0,
        showMeterReadings: invoiceConfigData.value?.showMeterReadings ?? false,
        showSavings: invoiceConfigData.value?.showSavings ?? false,
        dueDate: invoiceConfigData.value?.dueDate ?? new Date(),
        isExportPower: invoiceConfigData.value?.isExportPower ?? false,
        trackingCategoryReference: invoiceConfigData.value?.trackingCategoryReference ?? "",
        customer: {
          customerExternalIdentifier: invoiceConfigData.value?.customer?.customerExternalIdentifier ?? "",
          name: invoiceConfigData.value?.customer?.name ?? "",
          addressLineOne: invoiceConfigData.value?.customer?.addressLineOne ?? "",
          addressLineTwo: invoiceConfigData.value?.customer?.addressLineTwo ?? "",
          suburb: invoiceConfigData.value?.customer?.suburb ?? "",
          city: invoiceConfigData.value?.customer?.city ?? "",
          region: invoiceConfigData.value?.customer?.region ?? "",
          country: invoiceConfigData.value?.customer?.country ?? "",
          vatNumber: invoiceConfigData.value?.customer?.vatNumber ?? ""
        },
        contractFundEntity: {
          id : invoiceConfigData.value?.contractFundEntity.id ?? 0,
          name : invoiceConfigData.value?.contractFundEntity.name ?? "",
          registrationNumber : invoiceConfigData.value?.contractFundEntity.registrationNumber ?? "",
          address : invoiceConfigData.value?.contractFundEntity.address ?? "",
          vatNumber : invoiceConfigData.value?.contractFundEntity.vatNumber ?? "",
          xeroInvoiceAccountNumber : invoiceConfigData.value?.contractFundEntity.xeroInvoiceAccountNumber ?? "",
          contactEmailAddress : invoiceConfigData.value?.contractFundEntity.contactEmailAddress ?? "",
          contactTelephoneNumber : invoiceConfigData.value?.contractFundEntity.contactTelephoneNumber ?? "",
          bank : invoiceConfigData.value?.contractFundEntity.bank ?? "",
          accountName : invoiceConfigData.value?.contractFundEntity.accountName ?? "",
          accountNumber : invoiceConfigData.value?.contractFundEntity.accountNumber.toString() ?? "",
          branchCode : invoiceConfigData.value?.contractFundEntity.branchCode ?? ""
        },
        invoiceSavings:{
          id: invoiceConfigData.value?.invoiceSavings?.id ?? 0,
          invoiceId: invoiceConfigData.value?.invoiceSavings?.invoiceId ?? 0,
          amount: invoiceConfigData.value?.invoiceSavings?.amount ?? 0
        },
        invoiceDocument:{
          id: invoiceConfigData.value?.invoiceDocument?.id ?? 0,
          invoiceId:invoiceConfigData.value?.invoiceDocument?.invoiceId ?? 0,
          documentId: invoiceConfigData.value?.invoiceDocument?.documentId ?? 0,
          createDateTime: invoiceConfigData.value?.invoiceDocument?.createDateTime ?? new Date,
          updateDateTime: invoiceConfigData.value?.invoiceDocument?.updateDateTime ?? new Date,
          document:{
            id: invoiceConfigData.value?.invoiceDocument?.document?.id ?? 0,
            fileFormat: invoiceConfigData.value?.invoiceDocument?.document?.fileFormat  ?? "",
            documentType: invoiceConfigData.value?.invoiceDocument?.document?.documentType  ?? "",
            contentAsBase64String: invoiceConfigData.value?.invoiceDocument?.document?.contentAsBase64String  ?? "",
            createDateTime: invoiceConfigData.value?.invoiceDocument?.document?.createDateTime  ?? new Date,
            updateDateTime: invoiceConfigData.value?.invoiceDocument?.document?.updateDateTime  ?? new Date
          }
        },
        invoiceCharges: invoiceConfigData.value?.invoiceCharges?? [],
        meterReadings:invoiceConfigData.value?.meterReadings ?? [],
        invoiceDateFormat: invoiceConfigData.value?.invoiceDateFormat??"yyyy-MM-dd"
    }
})

const customer = computed(() => {
    return {
        name: invoiceConfigData.value?.customer?.name ?? "",
        address: `${invoiceConfigData.value?.customer?.addressLineOne}, ${invoiceConfigData.value?.customer?.addressLineTwo}, ${invoiceConfigData?.value?.customer?.suburb}
                        ${invoiceConfigData.value?.customer?.city}, ${invoiceConfigData.value?.customer?.region}`,
        vatNumber: invoiceConfigData.value?.customer?.vatNumber ?? "",
        customerExternalIdentifier: invoiceConfigData.value?.customer?.customerExternalIdentifier ?? ""
    }
})


/** Charges **/
const modifiedCharges = ref<(InvoiceDetailsCharge & { index: number })[]>([])
const mergedCharges = computed(
  () => (invoiceConfigData.value?.invoiceCharges ?? []).map(
    (charge, index) => {
      const updated = modifiedCharges.value.find(x => x.index === index)

      if (!updated) {
        return charge
      }

      return {
        ...charge,
        description: updated.description,
        rate: roundToMainCurrencyPrecision(updated.rateInFractionalCurrency / 100),
        quantity: updated.quantity,
      }
    }
  )
)
function handleChargeUpdate(newCharge: InvoiceDetailsCharge, index: number) {
  const existingModifiedChargeIndex = modifiedCharges.value.findIndex(x => x.index === index)

  if (existingModifiedChargeIndex < 0) {
    modifiedCharges.value.push({ ...newCharge, index })
  } else {
    modifiedCharges.value[existingModifiedChargeIndex] = { ...newCharge, index }
  }
}

function handleChargeDeletion(charge: InvoiceDetailsCharge) {
  const chargeId = charge.id

  // Do nothing if we don't have an ID.
  if (chargeId === undefined) {
    return
  }

  wrap(
    () => removeBillingCharge(chargeId)
  ).then(
    () => {
      addSuccess({
        title: 'Recurring charge removed.',
        allowHtml: true,
        message: `Recurring charge <b>${charge.description}</b> has been removed successfully.`
      })

      confirmChargeDeletion.value = undefined
    },
    () => addError({
      title: 'Recurring charge not removed.',
      allowHtml: true,
      message: `Recurring charge <b>${charge.description}</b> could not be successfully removed. Please reload the page and try again.`
    })
  )
}

watch(projectData, (newProjectData) => {

    poNumber.value = newProjectData?.poNumber ?? ""
    projectCode.value = newProjectData?.projectCode ?? 0
})

const handleShowMeterReadingsUpdate = (value: boolean) => {
    invoice.value.showMeterReadings = value
}

const handleShowSavingsUpdate = (value: boolean) => {
    invoice.value.showSavings = value
}

const handlePoNumberUpdate = (newPoNumber: string) => {
    poNumber.value = newPoNumber
}

const handleMeterReadingsUpdate = (newMeterReadings: InvoiceMeterReadings []) => {
    invoice.value.meterReadings = newMeterReadings
}

const updateBillingConfig = () => wrap(
    async () => {
      
      await Promise.all(
        modifiedCharges.value.map(
          x => updateBillingCharge({
            id: x.id!,
            description: x.description,
            quantity: x.quantity!
          })
        )
      )

      await updateMeterConfig(
        invoice.value.meterReadings.map(
          meterReading => ({
            id: meterReading.id,
            hide: meterReading.hide ?? false
          })
        )
      )

      await updateInvoiceConfig({
        id: invoice.value.billingConfigurationId,
        showMeterReadings:  invoice.value.showMeterReadings,
        showSavings: invoice.value.showSavings
      })

      await updateProject({
        projectCode: projectCode,
        poNumber: poNumber
      })
  })
.then(
    () => {
      addSuccess({ title: 'Invoice Updated' })

      modifiedCharges.value = []
    },
    () => addError({title: 'Unable to update invoice', message: 'Please try again'})
)

const navigateToInvoiceDashboard = () => {
    router.push({name: routeNames.assetInvoicesDashboard})
}

</script>

<template>
        <ContentLoader :loading="invoiceConfigLoading || projectLoading"></ContentLoader>
        <HeroSplashError v-if="invoiceConfigDataError" />
        <InvoiceDetails v-if="!invoiceConfigLoading && !invoiceConfigDataError" :is-dummy-invoice="true" heading="Tax Invoice Template"
                        :editable-charge-types="[ChargeType.Recurring]"
                        :removable-charge-types="[ChargeType.Recurring]"
                        placeholder="Will display here"
                        :capabilities="['charges:create']"
                        @create-charge="openAddChargeModal"
                        @remove-charge="confirmChargeDeletion = $event"


                        @update:charge="handleChargeUpdate"
                        :charges="
                          mergedCharges.map(
                            x => ({
                              id: x.id,
                              chargeType: x.chargeType,
                              quantity: x.quantity,
                              description: x.description,
                              rateInFractionalCurrency: x.rate * 100
                            })
                          )
                        "

                            :invoice="invoice"
                            :customer="customer"
                            :owner="owner"
                            :showMeterReadings="invoice.showMeterReadings"
                            :showSavings="invoice.showSavings"
                            :poNumber="poNumber"
                            :meterReadings="invoice.meterReadings"
                            @update:poNumber="handlePoNumberUpdate"
                            @update:showMeterReading="handleShowMeterReadingsUpdate"
                            @update:showSavings="handleShowSavingsUpdate"
                            @update:meter-readings="handleMeterReadingsUpdate">
        </InvoiceDetails>
        <div class="button-wrapper mt-4" v-if="!invoiceConfigLoading">
                <ButtonPrimary :icon="faFloppyDisk" class="color-temp-white" @click="updateBillingConfig">Save</ButtonPrimary>
                <Button :icon="faXmark" class="one-h-width" @click="navigateToInvoiceDashboard">Cancel</Button>
                <span class="thank-you-text">Thank you!</span>
        </div>

  <AddRecurringChargeModal v-if="addChargeModalIsOpen"
                           @close="closeAddChargeModal"
                           :asset-code="assetCode" />

  <ModalVariantConfirmation v-if="confirmChargeDeletion"
                            @reject="confirmChargeDeletion = undefined"
                            @confirm="handleChargeDeletion(confirmChargeDeletion)">
    <p>
      Are you sure you would like to delete the charge <b>{{ confirmChargeDeletion.description }}</b>
      for <b>{{ formatToMainCurrency(confirmChargeDeletion.rateInFractionalCurrency / 100) }}</b>
      from the invoice charges section of this invoice template?
    </p>
  </ModalVariantConfirmation>
</template>

<style scoped lang="scss">
    .button-wrapper {
    display: flex;
    gap: 10px;
    margin-bottom: 24px;
    .thank-you-text {
      font-size: 30px;
      font-weight: bold;
      text-transform: uppercase;
      margin-left: auto;
    }
  }
</style>
