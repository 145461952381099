<script setup lang="ts">
import {
    faArrowLeft,
    faEdit,
    faCircleInfo,
    faFloppyDisk,
    faXmark, faTriangleExclamation
} from "@fortawesome/pro-regular-svg-icons";
import { useAssetSettings, useAssetSettingsLookups, useSaveAssetSettings, useAssetCustomers } from "@/composables/assets/assets";
import { computed, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ModalContainer from "@/components/modal/modal-container.vue";
import ModalTitle from '@/components/modal/modal-title.vue';
import ModalContent from '@/components/modal/modal-content.vue';
import ModalFooter from '@/components/modal/modal-footer.vue';
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import Button from "@/components/button-secondary.vue";
import ButtonPrimary from "@/components/button-primary.vue";
import InvoiceDetailCard from "@/views/assetSetup/invoice-details-card.vue"
import TariffProfileCard from "@/views/assetSetup/tariff-profile-card.vue";
import BillingSettingsCard from "@/views/assetSetup/billing-settings-card.vue"
import ContractTermsCard from "@/views/assetSetup/contract-terms-card.vue"
import UtilityProfileCard from "@/views/assetSetup/utility-profile-card.vue"
import InvoiceContactCard from "@/views/assetSetup/invoice-contact-card.vue"
import { type Contact } from "@/features/contacts/schemas";
import routeNames from "@/router/names";
import { useForm } from "vee-validate";
import { z } from "zod";
import { toTypedSchema } from "@vee-validate/zod";
import useLoader from "@/composables/loader";
import useNotifications from "@/composables/notifications";
import { useUpdateContacts } from '@/composables/contacts/contacts';
import { ExportPowerEscalationType } from "@/features/billing/constants";
import { useSaveTariffs } from "@/composables/billing/tariffs";
import { useTariffsForAsset } from "@/composables/billing/tariffs";
import { navigateToCoreUrl } from "@/helpers/routing";
import { mainCurrency } from '@/helpers/validation'
import { createLogger } from '@/helpers/logging'
import { DeliveryStatus, MeterBillingType } from '@/features/assets/constants'
import { useContacts } from '@/composables/contacts/contacts';
import { CONTACT_TYPES } from "@/features/constants";
import GridContainer from '@/components/grid-container.vue'
import GridItem from '@/components/grid-item.vue'
import modalManualAsset from '@/views/assetSetup/modals/modal-edit-manual-asset.vue'
import { useModalState } from '@/composables/modal'

const logger = createLogger('views:assetSetup/setup.vue')
const { wrap: wrapInLoader } = useLoader()
const { addError, addSuccess, addWarning } = useNotifications()
const route = useRoute();
const router = useRouter();
const contractFundEntityId = ref<number>();
const selectedCustomerId = ref<string | null>();
const showGlobalError = ref<boolean>(false);

const assetCode = ref<string>(route.params.assetCode.toString())
const { mutateAsync: mutateContactsAsync } = useUpdateContacts(assetCode);
const { data: assetData, isError,error:assetDataError } = useAssetSettings(assetCode);
const { isError:isLookupsError, error:lookupsError  } = useAssetSettingsLookups();
const { isError:isContactsError, error: contactError } = useContacts(assetCode,CONTACT_TYPES.invoice);
const { isError: isAssetTariffsError, error: assetTariffsError } = useTariffsForAsset(assetCode)
const { mutateAsync } = useSaveAssetSettings(assetCode)
const { mutateAsync: mutateTariffsAsync } = useSaveTariffs(assetCode)
const { isError:isCustomerError, error:customerError} = useAssetCustomers(contractFundEntityId);
const showModalValidation = ref(false);
const showModalLeave = ref<string>();
var contacts:Contact[]
const isExportingPower = ref(false)
const isAutoApproving = ref(false)
const manualInputModalState = reactive(useModalState())

const { handleSubmit } = useForm({
  initialValues: {
    tariffProfile: {
      rates: {}
    }
  },
  validationSchema: computed(
    () => toTypedSchema(
      z.object({
        billingSettings: z.object({
          customerTag: z.string().nullable(),
          meterBillingType: z.nativeEnum(MeterBillingType),
          contractStartDate: z.date().nullable(),
          fundTransferDate: z.date().nullable(),
          firstEscalationMonth: z.number().int().gte(1).lte(12),
          firstEscalationYear: z.number().int().gte(new Date().getFullYear() - 6).lte(new Date().getFullYear() + 10),
          paymentDueInDays: z.number().int().gte(0),
          autoApprove: z.boolean(),
          gridExportValidation: isAutoApproving.value ? z.boolean() : z.boolean().optional(),
          expectedEnergyVariance: isAutoApproving.value ? z.number().int() : z.number().int().optional(),
          invoiceDateFormat: z.string().nullable(),
          billingCycle: z.object({
            id: z.number()
          })
        }),
        utilityProvider: z.object({
          utilityProviderId: z.number().int().gt(0),
          tariffStructureId: z.number().int().gt(0),
          blendedTariff: z.number().gt(0),
        }),
        contractTerms: z.object({
          assetDrawerNumber: z.number().nullish().optional(),
          degradationType: z.number().default(1),
        }),
        invoiceDetails: z.object({
          contractFundEntity: z.object({ id: z.number() }),
          customer: z.object({ customerExternalIdentifier: z.string() }),
        }),
        tariffProfile: z.object({
          tariffStructureId: z.number().int().gt(0),
          escalationDate: isExportingPower.value ? z.date() : z.date().optional(),
          escalationValue: isExportingPower.value ? z.number() : z.number().optional(),
          escalationType: isExportingPower.value ? z.nativeEnum(ExportPowerEscalationType) : z.nativeEnum(ExportPowerEscalationType).optional(),
          rates: z.record(
            z.string(),
            z.object({
              pvRate: mainCurrency(z.number()),
              exportRate: isExportingPower.value ? mainCurrency(z.number()) : mainCurrency(z.number()).optional(),
            })
          ).refine(
            rates => Object.keys(rates).length > 0,
            'At least one rate is required.'
          )
        })
      })
    )
  )
})

defineOptions({ name: 'AssetSetup' })


const updateInvoiceContact = (details: Contact[]) => {

    contacts = details.map(contact => ({ ...contact }));
}

const acceptAndSave = async () => {
    showModalValidation.value = false
    await validateAndSave();
    router.push({ name: routeNames.invoiceSetup, params: { assetCode: assetCode.value } })
}
const contactsCustomValidation =()=>{
    if(contacts.length==0){
      addError({ title: 'You need to add atleast one contact.' })
      return false
    } else if(contacts.length>=1 && contacts.filter(x=>{return x.primaryContact}).length==0){
     addError({ title: 'You need to set primary contact.' })
     return false
    }
    return true
}
const validateAndSave = handleSubmit(
  async values => {
    try {
      if(!contactsCustomValidation())
            return;

      const settings = assetData.value!
      contractFundEntityId.value =values.invoiceDetails.contractFundEntity.id;
      selectedCustomerId.value = values.invoiceDetails.customer.customerExternalIdentifier;

      const billingSettings = {
        meterBillingType: values.billingSettings.meterBillingType,
        customerTag: values.billingSettings.customerTag ?? null,
        contractStartDate: values.billingSettings.contractStartDate?? null,
        fundTransferDate: values.billingSettings.fundTransferDate?? null,
        firstEscalationMonth: new Date(`${values.billingSettings.firstEscalationYear}-${('0' + values.billingSettings.firstEscalationMonth.toString()).split('').slice(-2).join('')}-01T00:00:00Z`),
        paymentDueInDays: values.billingSettings.paymentDueInDays,
        autoApprove: values.billingSettings.autoApprove,
        gridExportValidation: values.billingSettings.gridExportValidation !== undefined ? values.billingSettings.gridExportValidation : false,
        expectedEnergyVariance: values.billingSettings.expectedEnergyVariance || 0,
        invoiceDate: 1,
        exportPower: false,
        exportPowerTariffInCents: 0,
        billingCycleId: 1,
        invoiceDateFormat: values.billingSettings.invoiceDateFormat
      }

      const contractTerms = { ...settings.contractTerms! }
      contractTerms.assetDrawerNumber = values.contractTerms.assetDrawerNumber || 0
      contractTerms.degradationType = values.contractTerms.degradationType
      const invoiceDetails = {
        contractFundEntityId: values.invoiceDetails.contractFundEntity.id,
        customerExternalIdentifier: selectedCustomerId.value
      }

      const updates = await wrapInLoader(
        async () => Promise.allSettled([
            mutateTariffsAsync({
              tariffStructureId: values.tariffProfile.tariffStructureId,
              exportPower: isExportingPower.value,
              rates: Object.entries(values.tariffProfile.rates).map(
                ([tariffType, rates]) => ({ tariffTypeId: parseInt(tariffType, 10), pvRate: rates.pvRate, exportRate: rates.exportRate })
              ),
              escalationDate: values.tariffProfile.escalationDate,
              escalationType: values.tariffProfile.escalationType,
              escalationValue: values.tariffProfile.escalationValue,
            }),
            mutateContactsAsync(contacts),
            mutateAsync({
              assetCode: assetCode.value,
              invoiceDetails,
              billingSettings:billingSettings||null,
              utilityProvider: {
                utilityProviderId: values.utilityProvider.utilityProviderId,
                tariffStructureId: values.utilityProvider.tariffStructureId,
                blendedTariff: values.utilityProvider.blendedTariff,
              },
              contractTerms:contractTerms||null,
              invoiceTemplates: null,
            })
          ])
      )

      // All updates were successful.
      if (updates.every(x => x.status === 'fulfilled')) {
        addSuccess({ title: 'Asset settings updated successfully.' })
      } else if (updates.every(x => x.status === 'rejected')) {
        addError({ title: 'Failed to save asset settings.' })
      } else {
        addWarning({
          title: 'Asset settings were partially updated.',
          message: 'Some settings failed to save correctly. Please check your input and try again.'
        })
      }

    } catch (e: unknown) {
      addError({ title: 'Failed to save asset settings.' })
    }
  },
  errors => {
    logger.warning('Form still contains validation errors', { errors: errors.errors, values: errors.values })
  }
)
const goBack = () => {
    showModalLeave.value = undefined
    showGlobalError.value = false
    navigateToCoreUrl(`Operations/AssetManagementDashboard`)
};
watch([isError,isCustomerError,isLookupsError,isContactsError,isAssetTariffsError], ([hasError,hasCustomerError,hasLookupsError,hasContactsError,hasAssetTariffsError]) => {
  showGlobalError.value=hasError||hasCustomerError||hasLookupsError||hasContactsError||hasAssetTariffsError;
}, { immediate: true });
const globalErrorMessage = computed(() => {
    return [customerError.value?.message??'',assetDataError.value?.message??'',lookupsError.value?.message??'',contactError.value?.message??'', assetTariffsError.value?.message??''].join("\r\n");
});

</script>
<template>
    <modalManualAsset v-if="manualInputModalState.isOpen" :assetCode="assetCode" @close="manualInputModalState.close()"></modalManualAsset>
    <ModalContainer v-if="showModalValidation">
        <ModalTitle>ENSURE ALL REQUIRED INFO IS PROVIDED</ModalTitle>
        <ModalContent :icon="faTriangleExclamation" icon-variant="warning">
          <div class="d-flex flex-column gap-3">
            <span>The following should please be completed to proceed:</span>
            <div>
              <span><strong>STEP 1:</strong> Link Meters associated with this asset</span>
              <br />
              <span><strong>STEP 2:</strong> Complete all inputs on the Settings tab</span>
              <br />
              <span><strong>STEP 3:</strong> Setup the Invoice Template with all recurring charges</span>
              <br />
            </div>
            <span>
              If meters were linked, they should auto-fill the Invoice Template.
              Review the displayed information to confirm accuracy for future invoices.
            </span>
          </div>
        </ModalContent>
        <ModalFooter class="text-end">
          <ButtonPrimary :icon="faCheck" @click="acceptAndSave">Okay</ButtonPrimary>
        </ModalFooter>
    </ModalContainer>
    <ModalContainer v-if="showModalLeave">
        <ModalTitle>ARE YOU SURE YOU WANT TO LEAVE THIS PAGE?</ModalTitle>
        <ModalContent :icon="faTriangleExclamation" icon-variant="warning">
          {{ showModalLeave }}
          <br /><br />
          Do you want to proceed?
        </ModalContent>
        <ModalFooter class="text-end">
            <Button :icon="faXmark" @click="showModalLeave = undefined" class="color-temp-white">No</Button>
            <ButtonPrimary :icon="faCheck" @click="goBack" class="color-temp-white ms-2">Yes</ButtonPrimary>
        </ModalFooter>
    </ModalContainer>
    <ModalContainer v-if="showGlobalError">
        <ModalTitle>OUR APOLOGIES</ModalTitle>
        <ModalContent :icon="faTriangleExclamation" icon-variant="warning">
          <b>Something went wrong.</b>
          <br/><br/>
          Please try reloading the page, alternatively log the following error with our support team:
          <br/><br/>
          {{ globalErrorMessage }}
          <br/><br/>
          Thank you for your patience.
        </ModalContent>
        <ModalFooter class="text-end">
            <ButtonPrimary :icon="faCheck" @click="goBack" class="color-temp-white">Okay</ButtonPrimary>
        </ModalFooter>
    </ModalContainer>
    <div class="wrapper">
        <div class="d-flex justify-content-between align-items-center top-items-wrapper">
            <div class="button-wrapper">
                <Button :icon="faArrowLeft" @click="showModalLeave = `If you made changes, remember to save before navigating away, alternatively they will be discarded.`" style="  width: 142px;">Back to Dashboard</Button>
                <Button v-if="assetCode.startsWith('M')"
                        :icon="faEdit"
                        @click="manualInputModalState.open()">
                  Edit Manual Inputs
                </Button>
            </div>
            <div class="d-flex align-items-center gap-2"
                v-if="assetData?.deliveryStatus?.name && assetData?.deliveryStatus?.name !== DeliveryStatus.Operational">
                <FontAwesomeIcon :icon="faCircleInfo" class="fa-4x color-temp-yellow" />
                <span>Complete these: <strong>1.</strong> Link Meters for this asset |
                    <strong>2.</strong> Enter all Asset Settings below | <strong>3.</strong> Setup
                    Invoice Template</span>
            </div>
        </div>

        <GridContainer :columns="['407px', 'auto']" :column-gap="27" :row-gap="27" v-if="!showGlobalError">
          <GridContainer :columns="['100%']" :row-gap="27">
              <InvoiceDetailCard :assetCode="assetCode" />
              <TariffProfileCard :asset-code="assetCode" @update:is-exporting-power="isExportingPower = $event" />
          </GridContainer>

          <GridContainer :columns="['430fr', '350fr']" :column-gap="27" :row-gap="27">
            <BillingSettingsCard :asset-code="assetCode" @update:auto-approve="isAutoApproving = $event" />
            <ContractTermsCard :asset-code="assetCode" />
            <GridItem span>
              <InvoiceContactCard :asset-code="assetCode" @update="updateInvoiceContact" />
            </GridItem>
          </GridContainer>

          <GridItem span>
            <UtilityProfileCard :asset-code="assetCode" />
          </GridItem>
        </GridContainer>

      <div class="button-wrapper mt-4" v-if="!showGlobalError">
            <ButtonPrimary :icon="faFloppyDisk" class="color-temp-white" @click="showModalValidation = true"
                v-if="assetData?.deliveryStatus?.name && assetData?.deliveryStatus?.name != 'Operational'">
                Save & Open
                Invoice Setup</ButtonPrimary>
            <Button :icon="faFloppyDisk" class="one-h-width" @click="validateAndSave">Save</Button>
            <Button :icon="faXmark" class="one-h-width" @click="showModalLeave = `If you cancel with unsaved information, it will be discarded, and you won’t be able to recover it.`">Cancel</Button>
        </div>
    </div>
</template>
<style scoped lang="scss">
.wrapper {
    .top-items-wrapper {
        margin-bottom: 17px;
        margin-right: 318px;
    }

    .one-h-width {
        width: 100px;
    }

    .button-wrapper {
        display: flex;
        gap: 10px;
        margin-bottom: 24px;
    }
}

.color-temp-yellow {
    color: #fee351;
}
</style>
